import * as React from "react";
import {
	useGetIdentity
} from "react-admin";

import Typography from '@mui/material/Typography';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import { ServerEnvTable } from "./ServerEnvTable"
import { BoxProducts } from "./BoxProducts"
import { BoxArticles } from "./BoxArticles"
import { BoxMedia } from "./BoxMedia"



const Item = styled(Paper)(({ theme }) => ({
	padding: theme.spacing(2),
}));



const WelcomeBox = () => {
	const { identity, loading: identityLoading } = useGetIdentity();

	if (identityLoading) {
		return <>Loading...</>;
	}
	return (
		<Typography variant="body1" component="p">
			Hallo <b>{identity?.fullName ?? 'Chef'}</b>! Du hast
			{identity?.groups?.includes("admin") ? ' Admin' : '' }
			{identity?.groups?.includes("employee") ? ' Mitarbeiter' : '' }
			&nbsp;Rechte.
		</Typography>
	)
}


class Dashboard extends React.Component {
	render() {
		return (
			<Box sx={{ flexGrow: 1, marginRight: '-16px', marginLeft: '-16px', backgroundColor: '#F5F5F5' }}>
				<Grid container spacing={2} sx={{padding: '1rem' }} >
					<Grid item xs={6}>
						<Item>
							<Typography variant="h4" component="h2" color="textSecondary">
								Welcome to the Administration
							</Typography>
							<br />
							<Typography variant="body1" component="p">
								Build version name <b>coconut mango juice</b>.
							</Typography>
							<WelcomeBox />
						</Item>
					</Grid>
					<Grid item xs={6}>
						<Item>
							<ServerEnvTable />
						</Item>
					</Grid>
					<Grid item xs={4}>
						<Item>
							<BoxProducts />
						</Item>
					</Grid>
					<Grid item xs={4}>
						<Item>
							<BoxArticles />
						</Item>
					</Grid>
					<Grid item xs={4}>
						<Item>
							<BoxMedia />
						</Item>
					</Grid>

				</Grid>
			</Box>
		);
	}
}

export default Dashboard;
