import * as React from 'react';
// import { createElement } from 'react';
import { DashboardMenuItem, Menu, MenuItemLink,
	// useResourceDefinitions,
	// useSidebarState,
	usePermissions,
} from 'react-admin';

import Divider from '@mui/material/Divider';

// import { useMediaQuery } from '@material-ui/core';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
// import DefaultIcon from '@mui/icons-material/ViewList';

import UserIcon from '@mui/icons-material/People';
import ProductsIcon from '@mui/icons-material/QrCode';
import ArticlesIcon from '@mui/icons-material/Article';
import RemarksIcon from '@mui/icons-material/Quiz';
import EuroIcon from '@mui/icons-material/Euro';
import MediaIcon from '@mui/icons-material/Collections';
import SettingsIcon from '@mui/icons-material/Settings';
import FrontendIcon from '@mui/icons-material/Store';

export const ppMenu = (props) => {
	// const resources = useResourceDefinitions()
	const { permissions } = usePermissions();
	let isAdmin = permissions?.includes("admin") ?? false;
	// console.log("resources: " + JSON.stringify(resources, null, 2));

	return (
	<Menu {...props}>
		<DashboardMenuItem />
		<Divider light variant="middle" />
		<MenuItemLink
			primaryText="Stoffe (Produkte)"
			leftIcon={<ProductsIcon />}
			to={{
					pathname: '/products',
					search: `filter=${JSON.stringify({ type: "fabric" })}`,
			}}
		/>
		{ isAdmin && 	<MenuItemLink to="/orders" primaryText="Bestellungen ✪" leftIcon={<ShoppingCartIcon />}/> }
		{ isAdmin && 	<MenuItemLink to="/transactions" primaryText="Transaktionen ✪" leftIcon={<EuroIcon />}/> }
		{ isAdmin && 	<MenuItemLink to="/shipments" primaryText="Versand ✪" leftIcon={<LocalShippingIcon />}/> }
		<Divider light variant="middle" />
		<MenuItemLink to="/articles" primaryText="Magazin" leftIcon={<ArticlesIcon />}/>
		<MenuItemLink to="/remarks" primaryText="Ratgeber" leftIcon={<RemarksIcon />}/>
		<Divider light variant="middle" />
		<MenuItemLink to="/media" primaryText="Media" leftIcon={<MediaIcon />}/>
		<Divider light variant="middle" />
		<MenuItemLink to="/users" primaryText="Nutzerkonten" leftIcon={<UserIcon />}/>
		{ isAdmin && 	<MenuItemLink to="/settings" primaryText="Einstellungen ✪" leftIcon={<SettingsIcon />}/> }
		<Divider light variant="middle" />
		<MenuItemLink to={process.env.REACT_APP_FRONTEND_URL} primaryText="Zum Frontend" leftIcon={<FrontendIcon />}/>
	</Menu>
	)
};
		// {Object.keys(resources).map(name => (
		// 	<MenuItemLink
		// 			key={name}
		// 			to={`/${name}`}
		// 			primaryText={
		// 					(resources[name]?.options?.label) ||
		// 					name
		// 			}
		// 			leftIcon={
		// 					resources[name]?.icon ? <resources.icon /> : <DefaultIcon />
		// 			}
		// 			onClick={props.onMenuClick}
		// 			sidebarIsOpen={open}
		// 	/>
		// ))}