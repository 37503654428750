import * as React from 'react';
import { AppBar, Toolbar, AppBarClasses } from 'react-admin';
// import { Fragment } from 'react';
import MUIAppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';

export const ppAppBar = (props) => {
	return (
		<AppBar
			{...props}
			style={{ height: "55px" }}
			position="fixed"
			container={MUIAppBar}
		>
			<Toolbar style={{ backgroundColor: "transparent", height: "100%", padding: "0px", width: "190px" }}>
				<img
					src="/logo.svg"
					alt="logo"
					className={AppBarClasses.logo}
					style={{ height: "55%" }}
				/>
			</Toolbar>
			<Typography
				variant="h6"
				className={AppBarClasses.title}
				color="inherit"
			>
				{process.env.REACT_APP_SERVER_ENV_SHORT} Verwaltungs-Oberfläche
			</Typography>
			<span className={AppBarClasses.spacer} />
		</AppBar>
	)
};