import * as React from "react";
import Typography from '@mui/material/Typography';

// for the Server Env Vars Table
import { styled } from '@mui/material/styles';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';



export const ServerEnvTable = () => {
	const timestamp = process.env.REACT_APP_BUILD_TIMESTAMP;
	const msTimestamp = timestamp * 1000;
	const dateObj = new Date(msTimestamp)
	const readableDate = dateObj.toLocaleString("de-DE", { weekday: 'long', year: 'numeric', month: 'long', day: '2-digit', hour: "2-digit", minute: "2-digit", second: "2-digit" });

	const envVarsRows = [
		{'name': 'NODE_ENV', 'var': process.env.NODE_ENV},
		{'name': 'REACT_APP_BUILD_TIMESTAMP', 'var': readableDate},
		{'name': 'REACT_APP_SERVER_ENV', 'var': process.env.REACT_APP_SERVER_ENV},
		{'name': 'REACT_APP_SERVER_ENV_SHORT', 'var': process.env.REACT_APP_SERVER_ENV_SHORT},
		{'name': 'REACT_APP_API_URL', 'var': process.env.REACT_APP_API_URL},
		{'name': 'REACT_APP_FRONTEND_URL', 'var': process.env.REACT_APP_FRONTEND_URL},
		{'name': 'REACT_APP_STATIC_IMAGES_URL', 'var': process.env.REACT_APP_STATIC_IMAGES_URL},
	];

	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
			backgroundColor: theme.palette.success.main,
			color: theme.palette.common.white,
		},
		[`&.${tableCellClasses.body}`]: {
			fontSize: 14,
		},
	}));

	const StyledTableRow = styled(TableRow)(({ theme }) => ({
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.action.hover,
		},
		// hide last border
		'&:last-child td, &:last-child th': {
			border: 0,
		},
	}));

	return (
		<>
			<Typography variant="h6" component="h6" color="textSecondary">
				Server Environmental Variables
			</Typography>
			<TableContainer  sx={{ maxWidth: '100%' }}>
			<Table sx={{ minWidth: 250}} aria-label="simple table">
				<TableHead>
					<StyledTableRow>
						<StyledTableCell align="right"><strong>Server Env Vars</strong></StyledTableCell>
						<StyledTableCell align="left"><strong>Value</strong></StyledTableCell>
					</StyledTableRow>
				</TableHead>
				<TableBody>
					{envVarsRows.map((row) => (
						<StyledTableRow
							key={row.name}
							sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
						>
							<StyledTableCell component="th" scope="row" align="right">{row.name}</StyledTableCell>
							<StyledTableCell align="left">{row.var}</StyledTableCell>
						</StyledTableRow>
					))}
				</TableBody>
			</Table>
			</TableContainer>
		</>
	)
}
