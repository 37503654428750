import * as React from "react";
import Typography from '@mui/material/Typography';
import {
	useGetList,
	useRedirect,
} from "react-admin";
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';

import { styled } from '@mui/material/styles';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';


// /////////////////////////////////////////////////////////////////////////////////////////////////
// collect data from db                                                                           //
// /////////////////////////////////////////////////////////////////////////////////////////////////
const TotalProducts = () => {
	const redirect = useRedirect();
	const handleClick = () => {
		redirect('/products?filter={"type":"fabric"}')
	}
	const { total, isLoading, error } = useGetList( 'products', {
		filter: {type: 'fabric'},
		pagination: {page: 1, perPage: 1},
		sort: {field: 'product_number', order: 'ASC'},
		},
	);
	if(isLoading) { return <strong>??</strong> }
	if(error) { return <strong>%%</strong> }
	return <Chip onClick={handleClick} size="small" variant="outlined" color="primary" label={total} />
}
const TotalPlissees = () => {
	const redirect = useRedirect();
	const handleClick = () => {
		redirect('/products?filter=%7B"type"%3A"fabric"%2C"properties"%3A%7B"fabric_class"%3A"plissee"%7D%7D')
	}
	const { total, isLoading, error } = useGetList( 'products', {
		filter: {type: 'fabric', properties: {fabric_class: 'plissee'}},
		pagination: {page: 1, perPage: 1},
		sort: {field: 'product_number', order: 'ASC'},
		},
	);
	if(isLoading) { return <strong>??</strong> }
	if(error) { return <strong>%%</strong> }
	return <Chip onClick={handleClick} size="small" variant="outlined" color="primary" label={total} />
}
const TotalWabenplissees = () => {
	const redirect = useRedirect();
	const handleClick = () => {
		redirect('/products?filter=%7B"type"%3A"fabric"%2C"properties"%3A%7B"fabric_class"%3A"wabenplissee"%7D%7D')
	}
	const { total, isLoading, error } = useGetList( 'products', {
		filter: {type: 'fabric', properties: {fabric_class: 'wabenplissee'}},
		pagination: {page: 1, perPage: 1},
		sort: {field: 'product_number', order: 'ASC'},
		},
	);
	if(isLoading) { return <strong>??</strong> }
	if(error) { return <strong>%%</strong> }
	return <Chip onClick={handleClick} size="small" variant="outlined" color="primary" label={total} />
}
const Manufacturer = ( param ) => {
	const redirect = useRedirect();
	const handleClick = () => {
		redirect(`/products?filter=%7B"type"%3A"fabric"%2C"manufacturer"%3A"${param.name}"%2C"properties"%3A%7B"fabric_class"%3A"${param.fabric}"%7D%7D`)
	}
	const { total, isLoading, error } = useGetList( 'products', {
		filter: {type: 'fabric', manufacturer: param.name, properties: {fabric_class: param.fabric}},
		pagination: {page: 1, perPage: 1},
		sort: {field: 'product_number', order: 'ASC'},
		},
	);
	if(isLoading) { return <strong>??</strong> }
	if(error) { return <strong>%%</strong> }
	return <Chip onClick={handleClick} size="small" variant="outlined" color="primary" label={total} />
}
const ManufacturerDrafts = ( param ) => {
	const redirect = useRedirect();
	const handleClick = () => {
		// redirect(`/products?filter=%7B"type"%3A"fabric"%2C"manufacturer"%3A"${param.name}"%2C"properties"%3A%7B"fabric_class"%3A"${param.fabric}"%7D%7D`)
		redirect(`/products?displayedFilters=%7B%22exists_published%22%3Atrue%7D&filter=%7B%22type%22%3A%22fabric%22%2C"manufacturer"%3A"${param.name}"%2C%22properties%22%3A%7B%22fabric_class%22%3A"${param.fabric}"%7D%2C%22exists_published%22%3A%22undefined%22%7D`)
	}
	const { total, isLoading, error } = useGetList( 'products', {
		filter: {type: 'fabric', manufacturer: param.name, properties: {fabric_class: param.fabric}, exists_published: "undefined"},
		pagination: {page: 1, perPage: 1},
		sort: {field: 'product_number', order: 'ASC'},
		},
	);
	if(isLoading) { return <strong>??</strong> }
	if(error) { return <strong>%%</strong> }
	return <Chip onClick={handleClick} size="small" variant="outlined" color="error" label={total} />
}
const TotalPublished = () => {
	const redirect = useRedirect();
	const handleClick = () => {
		redirect('/products?displayedFilters=%7B%22exists_published%22%3Atrue%7D&filter=%7B%22type%22%3A%22fabric%22%2C%22exists_published%22%3A%22true%22%7D')
	}
	const { total, isLoading, error } = useGetList( 'products', {
		filter: {type: 'fabric', published: true},
		pagination: {page: 1, perPage: 1},
		sort: {field: 'product_number', order: 'ASC'},
		},
	);
	if(isLoading) { return <strong>??</strong> }
	if(error) { return <strong>%%</strong> }
	return <Chip onClick={handleClick} size="small" color="success" label={total} />
}
const TotalPublishedButNotInStock = () => {
	const redirect = useRedirect();
	const handleClick = () => {
		redirect('/products?displayedFilters=%7B%22exists_inStock%22%3Atrue%2C%22exists_published%22%3Atrue%7D&filter=%7B%22type%22%3A%22fabric%22%2C%22exists_inStock%22%3A%22false%22%2C%22exists_published%22%3A%22true%22%7D')
	}
	const { total, isLoading, error } = useGetList( 'products', {
		filter: {type: 'fabric', published: true, inStock: false},
		pagination: {page: 1, perPage: 1},
		sort: {field: 'product_number', order: 'ASC'},
		},
	);
	if(isLoading) { return <strong>??</strong> }
	if(error) { return <strong>%%</strong> }
	return <Chip onClick={handleClick} size="small" color="primary" label={total} />
}
const TotalUnpublished = () => {
	const redirect = useRedirect();
	const handleClick = () => {
		redirect('/products?displayedFilters=%7B%22exists_published%22%3Atrue%7D&filter=%7B%22type%22%3A%22fabric%22%2C%22exists_published%22%3A%22false%22%7D')
	}
	const { total, isLoading, error } = useGetList( 'products', {
		filter: {type: 'fabric', published: false},
		pagination: {page: 1, perPage: 1},
		sort: {field: 'product_number', order: 'ASC'},
		},
	);
	if(isLoading) { return <strong>??</strong> }
	if(error) { return <strong>%%</strong> }
	return <Chip onClick={handleClick} size="small" color="error" label={total} />
}
	// get last edited product
	// get total amount of products edited within the last 5 days
	// get list of X last edited products


// <Typography variant="body1" component="p">
// 	{<TotalUnpublished />} <strong>Stoffe bedürfen akuter Aufmerksamkeit(!)</strong><br />
// 	<br />
// 	In der Datenbank befinden sich insgesamt {<TotalProducts />} Stoffe. <br />
// 	Davon sind {<TotalPlissees />} Plissees und {<TotalWabenplissees />} Wabenplissees.<br />
// 	KADECO ({<Manufacturer name="kadeco" fabric="plissee" />}/{<Manufacturer name="kadeco" fabric="wabenplissee"/>})<br />
// 	Ifasol ({<Manufacturer name="ifasol" fabric="plissee" />}/{<Manufacturer name="ifasol" fabric="wabenplissee"/>})<br />
// 	Teba ({<Manufacturer name="teba" fabric="plissee" />}/{<Manufacturer name="teba" fabric="wabenplissee"/>})<br />
// 	ffuss ({<Manufacturer name="ffuss" fabric="plissee" />}/{<Manufacturer name="ffuss" fabric="wabenplissee"/>})<br />
// 	<br />

// 	{<TotalPublished />} Stoffe sind veröffentlicht davon sind {<TotalPublishedButNotInStock />} aktuell rückständig.<br />
// 	<small>zuletzt bearbeiteter stoff: NAME LINK<br />
// 	in den letzten 5 tage bearbeietet stoffe: ANZAHL<br />
// 	wenn zahl kleiner 5 dann liste mit links<br /></small>
// </Typography>

// const StyledTableCell = styled(TableCell)(({ theme }) => ({
const StyledTableCell = styled(TableCell)(() => ({
	[`&.${tableCellClasses.head}`]: {
		// backgroundColor: theme.palette.success.main,
		// color: theme.palette.common.white,
		paddingLeft: 8,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
		paddingTop: 8,
		paddingBottom: 8,
		paddingLeft: 8,
	},
}));

export const BoxProducts = () => {
	return (
		<>
			<Typography variant="h6" component="h6" color="textSecondary">
				Stoffübersicht
			</Typography>
			<Typography variant="body1" component="p">
				{<TotalUnpublished />} <strong>Stoffe bedürfen akuter Aufmerksamkeit(!)</strong>
			</Typography>
			<Divider light={true} sx={{ marginTop: '.5em', marginBottom: '.5em' }} />
			<Typography variant="body1" component="p">
				In der Datenbank befinden sich insgesamt {<TotalProducts />} Stoffe.
				Davon sind nur {<TotalPublished />} bis jetzt veröffentlich und aktuell {<TotalPublishedButNotInStock />} rückständig.
			</Typography>

			<Typography variant="h6" component="h6" color="textSecondary" sx={{marginTop: '1em',}} >
				Liste der noch zu veröffentlichenden Stoffe
			</Typography>
			<TableContainer sx={{ maxWidth: '100%', marginBottom: '1em'}}>
				<Table sx={{ minWidth: 250}} aria-label="simple table">
					<TableHead>
						<TableRow>
							<StyledTableCell><strong>Hersteller</strong></StyledTableCell>
							<StyledTableCell><strong>Plissees</strong></StyledTableCell>
							<StyledTableCell><strong>Waben</strong></StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow>
							<StyledTableCell>KADECO</StyledTableCell>
							<StyledTableCell>{<ManufacturerDrafts name="kadeco" fabric="plissee"/>} von {<Manufacturer name="kadeco" fabric="plissee"/>}</StyledTableCell>
							<StyledTableCell>{<ManufacturerDrafts name="kadeco" fabric="wabenplissee"/>} von {<Manufacturer name="kadeco" fabric="wabenplissee"/>}</StyledTableCell>
						</TableRow>
						<TableRow>
							<StyledTableCell>Teba</StyledTableCell>
							<StyledTableCell>{<ManufacturerDrafts name="teba" fabric="plissee"/>} von {<Manufacturer name="teba" fabric="plissee" />}</StyledTableCell>
							<StyledTableCell>{<ManufacturerDrafts name="teba" fabric="wabenplissee"/>} von {<Manufacturer name="teba" fabric="wabenplissee" />}</StyledTableCell>
						</TableRow>
						<TableRow>
							<StyledTableCell>Ifasol</StyledTableCell>
							<StyledTableCell>{<ManufacturerDrafts name="ifasol" fabric="plissee"/>} von {<Manufacturer name="ifasol" fabric="plissee" />}</StyledTableCell>
							<StyledTableCell>{<ManufacturerDrafts name="ifasol" fabric="wabenplissee"/>} von {<Manufacturer name="ifasol" fabric="wabenplissee" />}</StyledTableCell>
						</TableRow>
						<TableRow>
							<StyledTableCell>ffuss</StyledTableCell>
							<StyledTableCell>{<ManufacturerDrafts name="ffuss" fabric="plissee"/>} von {<Manufacturer name="ffuss" fabric="plissee" />}</StyledTableCell>
							<StyledTableCell>{<ManufacturerDrafts name="ffuss" fabric="wabenplissee"/>} von {<Manufacturer name="ffuss" fabric="wabenplissee" />}</StyledTableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>

			<Typography variant="body1" component="p">
				Das macht insgesamt {<TotalPlissees />} Plissees und {<TotalWabenplissees />} Wabenplissees.
			</Typography>
			<Typography variant="caption" component="p" sx={{color: '#999'}}>
				WIP zuletzt bearbeiteter stoff: NAME LINK<br />
				in den letzten 5 tage bearbeietet stoffe: ANZAHL<br />
				wenn zahl kleiner 5 dann liste mit links
			</Typography>

		</>
		);
}



