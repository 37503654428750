import * as React from "react";
import {
	Layout,
	CheckForApplicationUpdate
} from 'react-admin';
import { ReactQueryDevtools } from 'react-query/devtools';
// side-nav menu with additional custom routes

import { ppAppBar } from './ppAppBar';
import { ppMenu } from "./ppMenu";
// import { PpAppUpdatedNotification } from './ppAppUpdatedNotification';



const UPDATE_CHECK_INTERVAL = 1 * 60 * 1000; // 1 minute

export const ppLayout = props => (
	<>
		<Layout
			{...props}
			appBar={ppAppBar}
			menu={ppMenu}
		/>
		<ReactQueryDevtools initialIsOpen={false} />
		{/*<CheckForApplicationUpdate interval={UPDATE_CHECK_INTERVAL} notification={<PpAppUpdatedNotification />} />*/}
		<CheckForApplicationUpdate interval={UPDATE_CHECK_INTERVAL} />
	</>
);