// import spacing from "@material-ui/core/styles/spacing";

// #FEEACC blass orange
// #FEF6E8 helles blass orange für flächen
// #fee9c5 schönes orange, hintergrund button main nav
// #e4e5e7 hell grau kastegorie balken
// #f7931d dunkel orange button
// #f58220 dunkel organe logo & font
// #636366 dunkel grau logo & font
// #9d9fa2 drunkleres grau icon
// #231f20 font color grau
// #f0f0f1 blass hell grau für flächen

import { defaultTheme } from 'react-admin';

const ppTheme = {
	...defaultTheme,
	// spacing: spacing,
	// fontFamily: "Roboto, sans-serif",
	typography: {
		// Use the system font instead of the default Roboto font.
		fontFamily: [
			"-apple-system",
			"BlinkMacSystemFont",
			'"Segoe UI"',
			"Arial",
			"sans-serif",
		].join(","),
	},
	sidebar: {
		width: 220
	},
	components: {
		...defaultTheme.components,
		RaLayout: {
			styleOverrides: {
				root: {
					"main.RaLayout-contentWithSidebar": {
						marginTop: "10px"
					}
				}
			}
		},
		RaTopToolbar: {
			...defaultTheme.components.RaTopToolbar,
			styleOverrides: {
				root: {
					alignItems: "center"
				}
			}
		},
		MuiTextField: {
			...defaultTheme.components.MuiTextField,
			styleOverrides: {
				root: {
					"& .MuiInputBase-root": {
						backgroundColor: "rgba(0,0,0,0.02)",
						"&:before": {
							borderBottomColor: "rgba(0,0,0,0.2)"
						}
					}
				}
			}
		},
		RaAppBar: {
			styleOverrides: {
				root: {
					backgroundColor: 'white',
					borderBottom: '0px solid #eee',
					boxShadow: '0px 0px 4px 0px rgba(0,0,0,.5)'
				}
			}
		},
		RaSidebar: {
			styleOverrides: {
				root: {
					marginTop: "4px",
					marginRight: "20px",
					"&.RaSidebar-appBarCollapsed": {
						marginTop: "0px"
					}
				}
			}
		},
		RaMenuItemLink: {
			styleOverrides: {
				root: {
					color: "#666666",
					borderLeft: '3px solid #fff', // invisible menu when not active, to avoid scrolling the text when selecting the menu
					"&.RaMenuItemLink-active": {
						borderLeft: '3px solid #f5850a',
						backgroundColor: '#fff2ed',
						color: "#222222",
						"& .RaMenuItemLink-icon path": {
							fill: "#f5850a"
						}
					}
				}
			}
		}
	},
	palette: {
		contrastThreshold: 3,
		tonalOffset: 0.2,

		primary: {
			// light: will be calculated from palette.primary.main,
			main: "#f5850a",
			// dark: will be calculated from palette.primary.main,
			// contrastText: will be calculated to contast with palette.primary.main
		},
		secondary: {
			// light: "#0066ff",
			main: "#FEF6E8",
			// dark: will be calculated from palette.secondary.main,
			// contrastText: "#ffcc00",
		},
		// error: will use the default color
		editorIconColor: {
			main: "#9d9fa2",
		},
		// primary1Color: "#f7931d",
		// primary2Color: "#f58220",
		// primary3Color: grey400,
		// accent1Color: redA200,
		// accent2Color: grey100,
		// accent3Color: grey500,
		// textColor: "#231f20",
		// alternateTextColor: white,
		// canvasColor: white,
		// borderColor: grey300,
		// disabledColor: darkBlack,
		// pickerHeaderColor: "#f58220",
		// clockCircleColor: grey500,
		// shadowColor: fullBlack,
	},
};

export default ppTheme