module.exports = {
	ra: {
		action: {
			add_filter: "Filter hinzufügen",
			add: "Neu",
			back: "Zurück",
			bulk_actions:
				"Ein Element ausgewählt |||| %{smart_count} Elemente ausgewählt",
			cancel: "Abbrechen",
			clear_input_value: "Eingabe löschen",
			clone: "Klonen",
			confirm: "Bestätigen",
			create: "Erstellen",
			create_item: "Erstelle %{item}",
			delete: "Löschen",
			edit: "Bearbeiten",
			export: "Exportieren",
			list: "Liste",
			refresh: "Aktualisieren",
			remove_filter: "Filter entfernen",
			remove_all_filters: 'Alle Filter entfernen',
			remove: "Entfernen",
			save: "Speichern",
			search: "Suchen",
			select_all: 'Alle auswählen',
			select_row: 'Diese Zeile auswählen',
			show: "Anzeigen",
			sort: "Sortieren",
			undo: "Zurücksetzen",
			unselect: "Auswahl aufheben",
			expand: "Expandieren",
			close: "Schließen",
			open_menu: 'Menü öffnen',
			close_menu: 'Menü schließen',
			update: 'Update',
			move_up: 'Nach oben',
			move_down: 'Nach unten',
			open: 'Öffnen',
			update_application: "Aktualisieren",
		},
		boolean: {
			true: "Ja",
			false: "Nein",
			null: "Leer Feld / keine Angabe",
		},
		page: {
			create: "%{name} erstellen",
			dashboard: "Dashboard",
			edit: "%{name} #%{id}",
			error: "Etwas ist schief gelaufen",
			list: "%{name}",
			loading: "Lädt",
			not_found: "Nicht gefunden",
			show: "%{name} #%{id}",
			empty: "Noch kein/e %{name}.",
			invite: "Soll eine hinzugefügt werden?",
			delete: "%{name} #%{id} löschen",
		},
		input: {
			file: {
				upload_several:
					"Zum Hochladen Dateien hineinziehen oder hier klicken, um Dateien auszuwählen.",
				upload_single:
					"Zum Hochladen Datei hineinziehen oder hier klicken, um eine Datei auszuwählen.",
			},
			image: {
				upload_several:
					"Zum Hochladen Bilder hineinziehen oder hier klicken, um Bilder auszuwählen.",
				upload_single:
					"Zum Hochladen Bild hineinziehen oder hier klicken, um ein Bild auszuwählen.",
			},
			references: {
				all_missing:
					"Die zugehörigen Referenzen konnten nicht gefunden werden.",
				many_missing:
					"Mindestens eine der zugehörigen Referenzen scheint nicht mehr verfügbar zu sein.",
				single_missing:
					"Eine zugehörige Referenz scheint nicht mehr verfügbar zu sein.",
			},
			password: {
				toggle_visible: "Passwort nicht zeigen",
				toggle_hidden: "Passwort zeigen",
			},
		},
		message: {
			about: "Über",
			are_you_sure: "Bist du sicher?",
			bulk_delete_content:
				'Möchten Sie "%{name}" wirklich löschen? |||| Möchten Sie diese %{smart_count} Elemente wirklich löschen?',
			bulk_delete_title:
				"Lösche %{name} |||| Lösche %{smart_count} %{name} Elemente",
			bulk_update_content:
				'Sicher das Sie %{name} aktualisieren wollen? |||| Sicher das Sie %{smart_count} Elemente aktualisieren wollen?',
			bulk_update_title:
				'Update %{name} |||| Update %{smart_count} %{name}',
			delete_content: "Möchten Sie diesen Inhalt wirklich löschen?",
			delete_title: "Lösche %{name} #%{id}",
			details: "Details",
			error:
				"Ein Fehler ist aufgetreten und ihre Anfrage konnte nicht abgeschlossen werden.",
			invalid_form:
				"Das Formular ist ungültig. Bitte überprüfen Sie ihre Eingaben.",
			loading: "Die Seite wird geladen.",
			no: "Nein",
			not_found: "Die Seite konnte nicht gefunden werden.",
			yes: "Ja",
			unsaved_changes:
				"Einigen Angaben wurden nicht gespeichert, sicher das Sie die Seite verlassen wollen?",
		},
		navigation: {
			no_results: "Keine Resultate gefunden",
			no_more_results: "Die Seite %{page} enthält keine Inhalte.",
			page_out_of_boundaries:
				"Die Seite %{page} liegt ausserhalb des gültigen Bereichs",
			page_out_from_end: "Letzte Seite",
			page_out_from_begin: "Erste Seite",
			page_range_info: "%{offsetBegin}-%{offsetEnd} von %{total}",
			partial_page_range_info:
				'%{offsetBegin}-%{offsetEnd} von mehr als %{offsetEnd}',
			current_page: 'Seite %{page}',
			page: 'Gehe zu Seite %{page}',
			first: 'Erste Seite',
			last: 'Letzte Seite',
			next: "Weiter",
			previous: 'Zurück',
			page_rows_per_page: "Zeilen pro Seite:",
			skip_nav: 'Springe zum Inhalt',
		},
		sort: {
			sort_by: 'Sortieren nach %{field} %{order}',
			ASC: 'Aufsteigend',
			DESC: 'Absteigend',
		},
		auth: {
			auth_check_error: "Bitte einloggen um fortzufahren",
			user_menu: "Profil",
			username: "Nutzername",
			password: "Passwort",
			sign_in: "Anmelden",
			sign_in_error: "Fehler bei der Anmeldung",
			logout: "Abmelden",
		},
		notification: {
			updated:
				"Element wurde aktualisiert |||| %{smart_count} Elemente wurden aktualisiert",
			created: "Element wurde erstellt",
			deleted:
				"Element wurde gelöscht |||| %{smart_count} Elemente wurden gelöscht",
			bad_item: "Fehlerhaftes Elemente",
			item_doesnt_exist: "Das Element existiert nicht",
			http_error: "Fehler beim Kommunizieren mit dem Server",
			data_provider_error:
				"dataProvider Fehler. Prüfe die Konsole für Details.",
			i18n_error:
				"Leider kann die Übersetung zur gewählten Sprache nicht geladen werden",
			canceled: "Aktion abgebrochen",
			logged_out: "Ihr Session wurde beendet. Bitte erneut verbinden.",
			not_authorized: "Sie haben keine Authorisierung diese Seite zu öffnen.",
			application_update_available: "Es steht eine neuere Version des Backends zur Verfügung.",
		},
		validation: {
			required: "Benötigt",
			minLength: "Muss mindestens %{min} Zeichen lang sein",
			maxLength: "Darf maximal %{max} Zeichen lang sein",
			minValue: "Muss mindestens %{min} sein",
			maxValue: "Muss %{max} oder weniger sein",
			number: "Muss eine Nummer sein",
			email: "Muss eine gültige E-Mail sein",
			oneOf: "Es muss einer sein von: %{options}",
			regex:
				"Es muss folgendem regulären Ausdruck entsprechen: %{pattern}",
		},
		saved_queries: {
			label: 'Filter Speichern',
			query_name: 'Filter Name',
			new_label: 'Speicher diese Suche...',
			new_dialog_title: 'Speichere die aktuelle Suche als',
			remove_label: 'Entferne die gespeicherte Suche',
			remove_label_with_name: 'Entferne Suche "%{name}"',
			remove_dialog_title: 'Sollte die gespeicherte Suche entfernt werden?',
			remove_message:
				'Sicher, dass diese Sucheeinstellung aus der Liste gelöscht werden soll?',
			help: 'Filtere die Liste und Speichere die Suche für später',
		},
	},
};
