import React from 'react';
// import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import './core/index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';

// change html title according to env
// get old title
const htmlTitle = document.title
// add prefix
const prefix = process.env.REACT_APP_SERVER_ENV_SHORT
document.title = `${prefix} ${htmlTitle}`


// React v18
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

// React v17
// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
