import * as React from "react";
// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {
	// Title,
	// useGetIdentity
} from "react-admin";

// const WelcomeBox = () => {
// 	const { identity, loading: identityLoading } = useGetIdentity();

// 	if (identityLoading) {
// 		return <>Loading...</>;
// 	}
// 	return (
// 		<Typography variant="body1" component="p">
// 			Hallo <b>{identity?.fullName ?? 'Chef'}</b>! Du hast
// 			{identity?.groups?.includes("admin") ? ' Admin' : '' }
// 			{identity?.groups?.includes("employee") ? ' Mitarbeiter' : '' }
// 			&nbsp;Rechte.
// 		</Typography>
// 	)
// }

export const BoxArticles = () => {

	return (
		<>
			<Typography variant="h6" component="h6" color="textSecondary">
				Magazinübersicht
			</Typography>
			<Typography variant="body1" component="p">
				Magazin Artikel gesamt: xxx.<br />
				Davon in trends:<br />
				wissenswertes: .....<br />
				XX artikel sind in bearbeitung und warten auf veröffentlichung.<br />
				XX artikel bedürfen direkter aufmerksamkeit.<br />
			</Typography>
			<Typography variant="body1" component="p">
				Bilder in der Media bibliothek gesamt: xxx.<br />
				die neusten Bilder: A B C D mit IDs<br />
				wissenswertes: .....<br />
				XX artikel sind in bearbeitung und warten auf veröffentlichung.<br />
				XX artikel bedürfen direkter aufmerksamkeit.<br />
			</Typography>
		</>
		);
}



