import * as React from "react";
import {
	Admin,
	Resource,
	fetchUtils,
	// ListGuesser
} from 'react-admin';


// /////////////////////////////////////////////////////////////////////////////////////////////////
// Loading Material UI Icons                                                                      //
// /////////////////////////////////////////////////////////////////////////////////////////////////
// import UserIcon from '@mui/icons-material/People';
// import ProductsIcon from '@mui/icons-material/QrCode';
// import ArticlesIcon from '@mui/icons-material/Article';


// /////////////////////////////////////////////////////////////////////////////////////////////////
// Importing resources for MODULES                                                                //
// /////////////////////////////////////////////////////////////////////////////////////////////////

// do not lazy load the dashboard (!)
import Dashboard from './modules/dashboard/Dashboard';

// lazy load modules components
const UserList = React.lazy(() => import('./modules/users/List'));

const ProductsList = React.lazy(() => import('./modules/products/List'));
const ProductsCreate = React.lazy(() => import('./modules/products/Create'));
const ProductsEdit = React.lazy(() => import('./modules/products/Edit'));

const MediaList = React.lazy(() => import('./modules/media/List'));
const MediaCreate = React.lazy(() => import('./modules/media/Create'));
const MediaEdit = React.lazy(() => import('./modules/media/Edit'));

const ArticlesList = React.lazy(() => import('./modules/articles/List'));
const ArticlesCreate = React.lazy(() => import('./modules/articles/Create'));
const ArticlesEdit = React.lazy(() => import('./modules/articles/Edit'));

const RemarksList = React.lazy(() => import('./modules/remarks/List'));
const RemarksCreate = React.lazy(() => import('./modules/remarks/Create'));
const RemarksEdit = React.lazy(() => import('./modules/remarks/Edit'));

const ShipmentsList = React.lazy(() => import('./modules/shipments/List'));
const TransactionsList = React.lazy(() => import('./modules/transactions/List'));

const OrdersList = React.lazy(() => import('./modules/orders/List'));
const OrdersEdit = React.lazy(() => import('./modules/orders/Edit'));

// import { OrdersShow } from './modules/orders/Show';



// /////////////////////////////////////////////////////////////////////////////////////////////////
// Importing additional resources für ADMIN Comp                                                  //
// /////////////////////////////////////////////////////////////////////////////////////////////////

// theming
import ppTheme from "./core/ppTheme";

// translation
import polyglotI18nProvider from "ra-i18n-polyglot";
import deMessages from "./core/raLanguageDeutsch";
import enMessages from "ra-language-english";

// API REST Client
import raPPApiRestClient from "./core/rest/raPPApiRestClient";

// layout
import { ppLayout } from './core/ppLayout';
import ppNotification from './core/ppNotification';


// /////////////////////////////////////////////////////////////////////////////////////////////////
// Setting up the imports for the ADMIN Comp                                                      //
// /////////////////////////////////////////////////////////////////////////////////////////////////

// translations
const messages = {
	de: deMessages,
	en: enMessages,
};
// const i18nProvider = locale => messages[locale];
const i18nProvider = polyglotI18nProvider(locale => messages[locale], "de", { allowMissing: true });

// security module
import authProvider from "./core/authProvider";

// /////////////////////////////////////////////////////////////////////////////////////////////////
// Data Provider                                                                                  //
// /////////////////////////////////////////////////////////////////////////////////////////////////

// CUSTOM HttpClient
const httpClient = (url, options = {}) => {
	if (!options.headers) {
		options.headers = new Headers({ Accept: "application/json" });
	}

	options.credentials = 'include';
	return fetchUtils.fetchJson(url, options);
};
// dataProvider switches API from dev to prod via ENV_VAR
const ppDataProvider = raPPApiRestClient(process.env.REACT_APP_API_URL, httpClient);


// /////////////////////////////////////////////////////////////////////////////////////////////////
// merging all the setup stuff together to startup the ADMIN comp framework                       //
// /////////////////////////////////////////////////////////////////////////////////////////////////
const App = () => {
	return (
		<Admin
			theme={ppTheme}
			dataProvider={ppDataProvider}
			layout={ppLayout}
			authProvider={authProvider}
			notification={ppNotification}
			dashboard={Dashboard}
			i18nProvider={i18nProvider}
			disableTelemetry
			requireAuth
		>
			{permissions => (
				<>
					{/* Only include the user resource for admin users */}
					{(permissions?.includes("admin") ?? false)
						? <Resource name="secrets"/>
						: null}
				<Resource name="products" list={ProductsList} edit={ProductsEdit} create={ProductsCreate} />
				<Resource name="media" list={MediaList} edit={MediaEdit} create={MediaCreate} />
				<Resource name="articles" list={ArticlesList} edit={ArticlesEdit} create={ArticlesCreate} />
				<Resource name="remarks" list={RemarksList} edit={RemarksEdit} create={RemarksCreate} />
				<Resource name="shipments" list={ShipmentsList}/>
				<Resource name="transactions" list={TransactionsList}/>
				<Resource name="orders" list={OrdersList} edit={OrdersEdit} />
				<Resource name="users" list={UserList}/>
				</>
			)}
		</Admin>
	)
}
export default App;